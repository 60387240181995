<template>
    <div class="checon-on-schedule">
        <div class="query">
            <el-select placeholder="请选择书籍..." v-model="books_id" @change="booksChange">
                <el-option v-for="item in works_list" :label="item.name" :key="item.id" :value="item.id"></el-option>
            </el-select>
            <div class="date-select">
                <i class="icon iconfont icon-triangle-left" @click="selectMonth(false)"></i>
                <div class="date">{{ formatTime(nowDate, 'YYYY年MM月') }}</div>
                <i :class="'icon iconfont icon-triangle-right ' + (getLatest() ? 'disabled' : '')"
                   @click="selectMonth(true)"></i>
            </div>
        </div>

        <div class="desc desc2">本月统计：{{ total_pages }}章&nbsp;&nbsp;&nbsp;&nbsp;{{ total_words }}字</div>
        <div class="tips">只统计有效章节和有效字数，鲸云轻小说考勤规则详见：
            <a href="https://guide.jyacg.com/#/attendance" target="_blank">鲸云考勤规则</a>
        </div>
        <div class="tips" v-if="getNoUpdateDays() < 2">本月全勤须达到：小全勤{{ getSmallNeed() }}字，大全勤{{ getBigNeed() }}字</div>
        <div class="tips tips2" v-else>断更超过两次,本月已无全勤资格</div>

        <div class="date-list">
            <div class="item" v-for="item in weekList" :key="item">{{ item }}</div>
            <div :class="{item: true, disabled: !it.isThis, active: it.date === formatTime(new Date())}"
                 v-for="it in dateList" :key="it.date">
                <div>
                    <div class="day">{{ it.day }}</div>
                    <div v-if="it.isThis && attendanceData[formatTime(new Date(it.date), 'YYYYMMDD')]"
                         class="update-num">{{ attendanceData[formatTime(new Date(it.date), 'YYYYMMDD')] }}更
                    </div>
                </div>
            </div>
        </div>

        <div class="desc">
            大全勤：非付费作品奖励金额{{ setting.max_not_attendance_money }}，付费作品奖励金额{{ setting.max_pay_attendance_money }}
        </div>
        <div class="desc">
            小全勤：非付费作品奖励金额{{ setting.min_not_attendance_money }}，付费作品奖励金额{{ setting.min_pay_attendance_money }}
        </div>
    </div>
</template>

<script>
import {getAttendance, AuthorCenter} from "@/https/mine_center.js";

export default {
    name: "checkon-admin",
    data() {
        return {
            works_list: [],
            books_id: null,
            attendanceData: {}, //考勤数据
            nowDate: new Date(new Date().setDate(1)),
            dateList: [],
            weekList: ['一', '二', '三', '四', '五', '六', '日'],
            total_pages: 0,
            total_words: 0,
            setting: {}
        }
    },
    mounted() {
        this.dateList = this.getMothDateList(this.nowDate);
        this.getBooks();
    },
    methods: {
        getBooks() {
            AuthorCenter().then((res) => {
                this.works_list = res.data;
                if (!res.data || !res.data[0]) {
                    this.$message.warning('你还未创建作品，抓紧时间创建吧！');
                    return;
                }
                this.books_id = res.data && res.data[0].id;
                this.getData();
            });
        },
        booksChange() {
            this.getData();
        },
        getData() {
            getAttendance({
                book_id: this.books_id,
                month: this.formatTime(this.nowDate, 'YYYY-MM')
            }).then(res => {
                if (res) {
                    this.attendanceData = res.data && res.data.list || {};
                    this.total_pages = res.data && res.data.total_pages || 0;
                    this.total_words = res.data && res.data.total_words || 0;
                    this.setting = res.data && res.data.setting || {};
                }
            })
        },
        selectMonth(next) {
            if (next) {
                if ((new Date().getFullYear() === this.nowDate.getFullYear()) && new Date().getMonth() === this.nowDate.getMonth()) return;
                this.nowDate = new Date(this.nowDate.setMonth(this.nowDate.getMonth() + 1));
                this.dateList = this.getMothDateList(this.nowDate);
            } else {
                this.nowDate = new Date(this.nowDate.setMonth(this.nowDate.getMonth() - 1));
                this.dateList = this.getMothDateList(this.nowDate);
            }
            this.getData();
        },
        getLatest() {
            return (new Date().getFullYear() === this.nowDate.getFullYear()) && new Date().getMonth() === this.nowDate.getMonth();
        },
        getNoUpdateDays(){
            let nowTimes = Number(this.formatTime(new Date(), 'YYYYMMDD'));
            let noUpdateDays = 0;
            for(let key in this.attendanceData){
                if(!this.attendanceData[key] && ( nowTimes > Number(key) )){
                    noUpdateDays ++
                }
            }
            return noUpdateDays;
        },
        getSmallNeed(){
          let days = this.getMonthDays(this.nowDate);
          let noUpdateDays = this.getNoUpdateDays();
          return noUpdateDays * 3000 + days * (this.setting.min_attendance_base_words || 0)
        },
        getBigNeed(){
          let days = this.getMonthDays(this.nowDate);
          let noUpdateDays = this.getNoUpdateDays();
          return noUpdateDays * 3000 + days * (this.setting.max_attendance_base_words || 0)
        }
    }
}
</script>

<style lang="scss" scoped>
.checon-on-schedule {
    .query{
        /deep/ input{
            line-height: 32px;
        }
    }
    .desc {
        margin: 25px 0;
        line-height: 30px;
        font-size: 16px;

        &.desc2 {
            margin: 15px 0;
            font-size: 14px;
        }
    }

    .tips {
        margin: 10px 0;
        font-size: 14px;
        color: #999;
        &.tips2{
            color: red;
        }

        a {
            color: #999 !important;
            text-decoration: underline;
        }
    }
}

.date-select {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    justify-content: center;

    .icon {
        font-size: 18px;
        cursor: pointer;

        &.disabled {
            color: #999;
        }
    }

    .date {
        margin: 0 15px;
        font-size: 16px;
        font-weight: 600;
    }
}

.date-list {
    display: flex;
    padding: 20px 15px;
    flex-wrap: wrap;
    border: 1px solid #EEEEEE;

    .item {
        width: 14.285%;
        margin: 17px 0;
        text-align: center;

        & > div {
            width: 76px;
            min-height: 41px;
            margin: 0 auto;
            background: #FFFFFF;
            border-radius: 5px;
        }

        &.disabled {
            .day {
                color: #999;
            }
        }

        &.active {
            & > div {
                border: 1px solid #409EFF;
            }

            .day {
                color: #409EFF;
            }
        }

        .update-num {
            width: 50px;
            margin: 0 auto;
            line-height: 18px;
            font-size: 12px;
            color: #FFFFFF;
            background: #D9001B;
            border-radius: 5px;
        }
    }
}
</style>
